import React from 'react';

const CircledArrowIcon = () => {
	return (
		<svg className='circled-arrow-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
			<g data-name='Group 761'>
				<g data-name='Rectangle 485' fill='#fff' stroke='#00275e'>
					<rect width='30' height='30' rx='15' stroke='none'/>
					<rect x='.5' y='.5' width='29' height='29' rx='14.5' fill='none'/>
				</g>
				<g data-name='Group 63'>
					<g data-name='Group 61'>
						<path data-name='Line 51' fill='none' stroke='#00275e' strokeLinecap='round' d='M15.028 19.5 9.472 14'/>
					</g>
					<g data-name='Group 62'>
						<path data-name='Line 51' fill='none' stroke='#00275e' strokeLinecap='round' d='m15.028 19.528 5.5-5.556'/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default CircledArrowIcon;