import React from 'react';

const ClearIcon = () => {
	return (
		<svg className='clear-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
			<g stroke='#00275e'>
				<g fill='#fff'>
					<rect width='30' height='30' rx='15' stroke='none'/>
					<rect x='.5' y='.5' width='29' height='29' rx='14.5' fill='none'/>
				</g>
				<g fill='none' strokeLinecap='round'>
					<path d='M20.087 9.91 9.91 20.087'/>
					<path d='M20.088 20.088 9.912 9.911'/>
				</g>
			</g>
		</svg>
	);
};

export default ClearIcon;