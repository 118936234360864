import React, { useRef } from 'react';
import { IHeartcorePictureQuery } from '../types/global.types';
import { IMedia } from '../types/heartcore.types';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import { useEffect } from 'react';
import { useState } from 'react';

type HeartcorePictureProps = {
	queries?: IHeartcorePictureQuery[];
	image:IMedia;
}

const HeartcorePicture = ({ image, queries = null }: HeartcorePictureProps) => {

	const imageFocalPoint = image?.umbracoFile?.focalPoint || { top: 0.5, left: 0.5 };
	if (!image) { return <></>; }
	const { _url: src, altText: altText } = image;
	const ref = useRef(null);
	const isIntersecting = useIntersectionObserver(ref, {
		root: null,
		threshold: 0,
		rootMargin: '200px',
	}, false);
	const [ imageIsLoaded, setImageIsLoaded ] = useState(false);
	const [ initialLoad, setInitialLoad ] = useState(false);

	const lazyLoadImage = () => {
		const img = ref.current.querySelector('img');
		if(img) {
			ref.current.querySelectorAll('source').forEach((source:HTMLSourceElement) => {
				source.srcset = source.dataset.srcset;
			});
	
			img.src = img.dataset.src;
			if (img.complete) {
				setImageIsLoaded(true);
			} else {
				img.addEventListener('load', () => {
					setImageIsLoaded(true);
				});
				img.addEventListener('error', (_e:Event) => {
					console.error(`Image ${img.src} could not be loaded.`);
					ref.current.classList.add('missing-image');
				});
			}
		}
	};

	useEffect(() => {
		if(isIntersecting && !initialLoad) {
			lazyLoadImage();
			setInitialLoad(true);
		}
	}, [isIntersecting]);
	
	return (
		<picture ref={ref} className={`heartcore-picture ${imageIsLoaded ? 'heartcore-picture--loaded' : ''}`}>
			{queries && queries.map(q => 
				<source key={q.alias} data-srcset={`${src}?anchor=center&width=${q.width}&height=${q.height}&mode=crop&center=${imageFocalPoint.top},${imageFocalPoint.left}`} media={q.media} />,
			)}
			<img data-src={src} alt={altText} className='heartcore-picture__image' />
		</picture>
	);
};

export default HeartcorePicture;
