import React from 'react';

const DownloadIcon = () => {
	return (
		<svg className='download-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.47 9.207'>
			<g id='Group_1146' data-name='Group 1146' transform='translate(.707 .707)'>
				<g id='Group_63' data-name='Group 63'>
					<g id='Group_61' data-name='Group 61' transform='translate(0 .028)'>
						<path id='Line_51' data-name='Line 51' fill='none' stroke='#fff' strokeLinecap='round' strokeWidth='1' d='M5.556 5.5 0 0'/>
					</g>
					<g id='Group_62' data-name='Group 62' transform='rotate(90 5.528 5.528)'>
						<path id='Line_51-2' data-name='Line 51' fill='none' stroke='#fff' strokeLinecap='round' strokeWidth='1' d='M5.556 5.5 0 0'/>
					</g>
				</g>
				<path id='Path_532' data-name='Path 532' d='M0 8h11.1' fill='none' stroke='#fff' strokeLinecap='round' strokeWidth='1'/>
			</g>
		</svg>
	);
};

export default DownloadIcon;