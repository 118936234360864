import React, { useContext } from 'react';
import { ITextBanner } from '../types/heartcore.types';
import RawHtml from './raw-html';
import LokaltogContext from '../context/context';

const TextBanner = (props: ITextBanner) => {
	const { leftSideContent, rightSideContent, backgroundColor, disableTopMargin, disableBottomMargin } = props;
	const context = useContext(LokaltogContext);
	
	return (
		<section 
			className={`
				text-banner 
				${ context.windowWidth > 768 ? 'box-wide': ''} 
				text-banner--${backgroundColor?.label}-background 
				${disableTopMargin ? 'text-banner--no-top-margin' : ''} 
				${disableBottomMargin ? 'text-banner--no-bottom-margin' : ''}
			`} 
			style={{ backgroundColor: `#${backgroundColor?.color}` } }
		>
			<div className='text-banner__content box-wide'>
				<div className='text-banner__section text-banner__section--left'><RawHtml content={leftSideContent} /></div>
				<div className='text-banner__section text-banner__section--right'><RawHtml content={rightSideContent} /></div>
			</div>
		</section>
	);
};

export default TextBanner;
