import React from 'react';
import { IIFrame } from '../types/heartcore.types';
import RawHtml from './raw-html';

const IFrame = (props: IIFrame) => {
	const { iFrame } = props;
	return (
		<section className='box-wide'>
			<RawHtml content={iFrame} />
		</section>
	);
};

export default IFrame;
