import React from 'react';

const NotificationIcon = () => {
	return (
		<svg className='notification-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'>
			<g  fill='#fff' stroke='red'>
				<rect width='22' height='22' rx='4' stroke='none'/>
				<rect x='.5' y='.5' width='21' height='21' rx='3.5' fill='none'/>
			</g>
			<rect width='3' height='11' rx='1.5' transform='translate(10 3)' fill='red'/>
			<circle cx='1.5' cy='1.5' r='1.5' transform='translate(10 15)' fill='red'/>
		</svg>
	);
};

export default NotificationIcon;