import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { IDropdownFormField, IFormField, IFormFieldRule, IFormPicker } from '../types/heartcore.types';
import LokaltogContext from '../context/context';
import VerificationIcon from './svg/verification-icon';
import NotificationIcon from './svg/notification-icon';
import { fetchData, fetchHeartcoreData } from '../services/fetch-data';
import { checkFieldCondition } from '../utils/form/check-field-condition';
import { getDefaultValue } from '../utils/form/get-default-value';
import { cleanUpFileUploads, isFileUploadField } from '../utils/form/file-upload-helpers';
import { getFieldInput } from '../utils/form/get-field-input';
import { constructEmailHTML } from '../utils/form/construct-email-html';

/**
	 * we need to replace value with key on input types that have prevalues as object;
	 * we keep key-value on radio, checkboxes and dropdowns as Umbraco expects to get the key on the form submit if the prevalues are object (it can also be an array);
	 * however for the condition rule Umbraco saves a value, so we counteract that with replacing value with the actual key for easier check later on in he check-field-condition
	 */
const updateConditionKey = (rules: IFormFieldRule[], siblings: IFormField[]) => { 
	rules.forEach(rule => {
		const ruleProperty = siblings.find(input => input.alias === rule.field);

		if(!ruleProperty) { return; }

		if(ruleProperty.type === 'radio' || ruleProperty.type === 'select' || ruleProperty.type === 'checkboxList') {
			const prevalues = (ruleProperty as IDropdownFormField).preValues;
			if(!Array.isArray(prevalues)) {
				for(const key in prevalues) {
					if(prevalues[key] === rule.value) {
						rule.value = key;
					}
				}
			}
		} else if(ruleProperty.type === 'checkbox') { //if it's checkbox condition in the backoffice the value will be either 'true' or 'false'
			rule.value = JSON.parse(rule.value);
		}
	});
};

const FormPicker = (props: IFormPicker) => {
	const { selectedForm, fileUploadsFolder, formSubmissionEmail, formSubmissionFeedbackEmail, disableBottomMargin, disableTopMargin } = props;
	const [ formData, setFormData] = useState<Map<string, IFormField>>(null);
	const [ formFieldsArray, setFormFieldsArray] = useState<IFormField[]>(null);
	const [ defaultValues, setDefaultValues] = useState({});
	const context = useContext(LokaltogContext);
	const [ formSubmissionError, setFormSubmissionError ] = useState('');
	const [ formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

	useEffect(() => {
		if(selectedForm) {
			const fields = new Map();
			selectedForm.pages[0].fieldsets.map(fieldset => {
				fieldset.columns.map(column => {
					column.fields.map(field => {
						if(field.condition) {
							updateConditionKey(field.condition.rules, column.fields);
						}
						fields.set(field.alias, field);
					});
				});
			});
			setFormData(fields);
		}

	}, []);

	const updateDefaultValues = () => {
		const tempArray = [];
		let newDefaultValues = {};

		formData.forEach((field, alias) => {
			tempArray.push(field);
			newDefaultValues = {
				...newDefaultValues,
				...getDefaultValue(alias, field),
			};
		});

		setFormFieldsArray(tempArray);
		setDefaultValues(newDefaultValues);
	};

	useEffect(() => {
		if(!formData) { return; }
		updateDefaultValues();
	}, [formData]);

	if(!formFieldsArray || !defaultValues) { return <></>; }

	const validateHandler = (values) => {
		const errors = {};
		for(const key in values) {
			const fieldObj = formData.get(key);
			let satisfiesCondition = true;

			if(fieldObj.condition) {
				satisfiesCondition = checkFieldCondition(fieldObj.condition, values);
			}

			if (fieldObj.required  && satisfiesCondition
				&& 
					((isFileUploadField(fieldObj) && (!values[key] || values[key].length === 0)) 
					|| (!values[key]))
			) {
				errors[key] = fieldObj.requiredErrorMessage || fieldObj.settings.errorMessage || context.tr('FormPicker.Error');
			}
			if(satisfiesCondition && values[key] && fieldObj.settings.pattern) {
				const regex = new RegExp(fieldObj.settings.pattern);
				if(!regex.test(values[key])) {
					errors[key] = fieldObj.settings.patternInvalidErrorMessage || fieldObj.requiredErrorMessage || fieldObj.settings.errorMessage || context.tr('FormPicker.Error');
				}
			}
		}

		return errors;
	};


	const submitHandler = async ( values, { setSubmitting }) => {
		try {
			cleanUpFileUploads(values, formData);
			const data = await fetchHeartcoreData(`https://api.umbraco.io/forms/${selectedForm._id}/entries`, 
				{ 
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Api-Version': '2.1',
					},
					body: JSON.stringify(values),
					isJson: false,
				});

			if(data.error) {
				throw new Error('Could not submit Umbraco\'s form');
			}

			const html = constructEmailHTML(formSubmissionEmail.emailContent, values, formData, selectedForm.name);

			const feedbackhtml = constructEmailHTML(formSubmissionFeedbackEmail.emailContent, values, formData, selectedForm.name);
			const body = {
				formSubmissionEmail: {
					from: formSubmissionEmail.senderEmail,
					to: formSubmissionEmail.receiverEmail.replace(/\s/g, '').split(','),
					subject: formSubmissionEmail.emailSubject,
					html: html,
				},
				formSubmissionFeedbackEmail: {
					from: formSubmissionFeedbackEmail.senderEmail,
					to: [values[formSubmissionFeedbackEmail.receiverEmail.trim()]],
					subject: formSubmissionFeedbackEmail.emailSubject,
					html: feedbackhtml,
				},
			};

			const sendEmailsResponse = await fetchData(process.env.GATSBY_EMAIL_SERVICE_API_URL, {
				method:'POST',
				body: JSON.stringify(body),
			});

			if(!sendEmailsResponse.ok) {
				throw new Error('Could not send out emails');
			}

			setFormHasBeenSubmitted(true);
			setSubmitting(false);
		} catch(e) {
			console.error(e);
			setSubmitting(false);
			setFormSubmissionError(context.tr('FormPicker.FormSubmissionError'));
		}
	};

	return (
		<div className={`form-picker box-wide ${disableTopMargin ? 'form-picker--no-top-margin' : ''} ${disableBottomMargin ? 'form-picker--no-bottom-margin' : ''}`}>
			<div className='form-picker__inner'>
				<h2>{selectedForm.name}</h2>
				<Formik
					initialValues={defaultValues}
					validate={validateHandler} 
					onSubmit={submitHandler}
				>
					{formik =>	<Form noValidate>
						{formFieldsArray.map(field => getFieldInput(field, fileUploadsFolder))} 
						<div className='form-picker__submit-group-wrapper'>
							<button className='button' disabled={formHasBeenSubmitted || formik.isSubmitting} type='submit'>{selectedForm.submitLabel}</button>
							<div className='form-picker__submit-progress'>
								{formik.isSubmitting && <div className='spinner'></div>}
								{formHasBeenSubmitted &&
									<>
										<VerificationIcon />
										<p className='form-picker__submit-message'>{selectedForm.messageOnSubmit}</p> 
									</>
								}
								{formSubmissionError && 
									<>
										<NotificationIcon />
										<p className='form-picker__submit-message form-picker__submit-message--error'>{formSubmissionError}</p> 
									</>
								}
							</div>
						</div>
					</Form>}
				</Formik>
			</div>
		</div>
	);
};

export default FormPicker;
