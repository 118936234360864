import { useEffect, useState } from 'react';
import { IFormField } from '../types/heartcore.types';
import { useFormikContext } from 'formik';
import { checkFieldCondition } from '../utils/form/check-field-condition';

export function useFieldConditioning(field: IFormField) {
	const [ showInput, setShowInput ] = useState(!field.condition);
	const { values } = useFormikContext();

	useEffect(() => {
		if(field.condition) {
			const satisfiesCondition = checkFieldCondition(field.condition, values);
			setShowInput(satisfiesCondition);
		}
	}, [values]);

	return showInput;
}