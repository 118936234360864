import { IFormField } from '../../types/heartcore.types';

export const isFileUploadField = (field: IFormField) => {
	if(field.type !== 'hidden') { return false; }
	const sanitizedDefaultValue = field.settings?.defaultValue?.trim().toLowerCase();
	return sanitizedDefaultValue === 'file';
};

export const cleanUpFileUploads = (formData, values) => {
	for(const key in values) {
		const fieldObj = formData.get(key);
		if (isFileUploadField(fieldObj) && values[key].trim().toLowerCase() === 'file') {
			values[key] = null;
		}
	}
};
