import React, { useContext } from 'react';
import LokaltogContext from '../context/context';
import { IDocuments } from '../types/heartcore.types';

const Documents = (props: IDocuments) => {
	const { documents, disableTopMargin, disableBottomMargin } = props;
	const context = useContext(LokaltogContext);

	return (
		<section className={`documents box-wide ${disableTopMargin ? 'documents--no-top-margin' : ''} ${disableBottomMargin ? 'documents--no-bottom-margin' : ''}`}>
			{documents?.map(d => (
				<div className='documents__document' key={d.headline}>
					<h3 className='documents__document__headline'>{d.headline}</h3>
					<a
						className='button'
						href={d.document?._url || ''}
						download
						target='_blank'
						rel='noreferrer'
					>
						{context.tr('Global.Download')}
					</a>
				</div>
			))}
		</section>
	);
};

export default Documents;
