import React from 'react';
import Checkbox from '../../components/form/checkbox';
import CheckboxList from '../../components/form/checkbox-list';
import DatePickerInput from '../../components/form/date-picker-input';
import Dropdown from '../../components/form/dropdown';
import FileUpload from '../../components/form/file-upload';
import Input from '../../components/form/input';
import Radio from '../../components/form/radio';
import Recaptcha from '../../components/form/recaptcha';
import Textarea from '../../components/form/textarea';
import TitleAndDescription from '../../components/form/title-and-description';
import { ICheckboxList, IDropdownFormField, IFormField, IRecaptcha, ITitleAndDescription } from '../../types/heartcore.types';
import { isFileUploadField } from './file-upload-helpers';

export const getFieldInput = (field: IFormField, fileUploadsFolder) => {
	let input;
	switch(field.type) {
		case ('text'):
			input = <Input 
				key={field.alias} 
				{...field} 
			/>;
			break;
		case ('textarea'):
			input = <Textarea
				key={field.alias}
				{...field}
			/>;
			break;
		case ('checkbox'):
			input = <Checkbox
				key={field.alias}
				{...field}
			/>;
			break;
		case ('checkboxList'):
			input = <CheckboxList
				key={field.alias}
				{...field as ICheckboxList}
			/>;
			break;
		case ('radio'):
			input = <Radio
				key={field.alias}
				{...field as ICheckboxList}
			/>;
			break;
		case ('titleAndDescription'):
			input = <TitleAndDescription
				key={field.alias}
				{...field as ITitleAndDescription}
			/>;
			break;
		case ('date'):
			input = <DatePickerInput
				key={field.alias}
				{...field}
			/>;
			break;
		case ('hidden'): {
			if(isFileUploadField(field)) {
				input = <FileUpload key={field.alias} fileUploadsFolder={fileUploadsFolder} {...field} />;
			}
			break;
		}
		case ('recaptcha2'):
			input = <Recaptcha key={field.alias} {...field as IRecaptcha} />;
			break;
		case ('select'):
			input = <Dropdown {...field as IDropdownFormField} key={field.alias}></Dropdown>;
			break;
	}

	return input;
};
