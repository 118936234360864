import React from 'react';
import { IModule } from '../types/heartcore.types';
import Accordion from './accordion';
import Documents from './documents';
import FormPicker from './form-picker';
import Hero from './hero';
import HeroCarrusel from './hero-carrusel';
import IFrame from './iframe';
import ImageBanner from './image-banner';
import ImageGallery from './image-gallery';
import InteractiveTrain from './interactive-train';
import MediaBanner from './media-banner';
import MultiFormPicker from './multi-form-picker';
import Notifications from './notifications';
import Quote from './quote';
import RichTextModule from './rich-text-module';
import TextBanner from './text-banner';
import ThreeTilesModule from './three-tiles-module';

type ModulesControllerProps = {
	modules: IModule[];
	className?: string;
}

const moduleVariants = {
	imageBanner: ImageBanner,
	hero: Hero,
	heroCarousel: HeroCarrusel,
	richTextModule: RichTextModule,
	threeTilesModule: ThreeTilesModule,
	notifications: Notifications,
	quote: Quote,
	formSelector: FormPicker,
	multiFormSelector: MultiFormPicker,
	mediaBanner: MediaBanner,
	iFrame: IFrame,
	textBanner: TextBanner,
	documents: Documents,
	imageGallery: ImageGallery,
	interactiveTrain: InteractiveTrain,
	accordion: Accordion,
};

const Module = (props) => {
	const ModuleComponent = moduleVariants[props.contentTypeAlias];
	if(!ModuleComponent) return null;

	return <ModuleComponent {...props} />;
};

const ModulesController = ({ modules, className = '' }: ModulesControllerProps) => (
	<>
		{modules?.map((m, index) => <Module key={`${m.contentTypeAlias}-${index}`} {...m} className={className}/>)}
	</>
);

export default ModulesController;
