import React from 'react';

const PlayIcon = () => {
	return (
		<svg className='play-icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 126 126'>
			<g id='Group_814' data-name='Group 814' transform='translate(-612 -935)'>
				<circle id='Ellipse_246' data-name='Ellipse 246' cx='63' cy='63' r='63' transform='translate(612 935)' fill='#6593d5'/>
				<path id='Path_475' data-name='Path 475' d='m657.55 971.016 47.033 27.125-47.033 27.064Z' fill='#fff' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'/>
			</g>
		</svg>
	);
};

export default PlayIcon;