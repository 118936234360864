import { checkFieldCondition } from './check-field-condition';
import { isFileUploadField } from './file-upload-helpers';
import { getPrevalues } from './get-prevalues';
import { replaceMarkers } from './replace-markers';

export const constructEmailHTML = (emailContent, values, formData, name) => {
	const dataArray = [];
	let content;
	for(const key in values) {
		const fieldObj = formData.get(key);
		let satisfiesCondition = true;

		if(fieldObj.condition) {
			satisfiesCondition = checkFieldCondition(fieldObj.condition, values);
		}

		if(satisfiesCondition) { 
			if (isFileUploadField(fieldObj)) {
				dataArray.push(`
					<div>${fieldObj.caption}: 
						${values[key] && values[key].length > 0 ? values[key].map(value => `<br><a href=${value}>${value}</a>`): '-'}
					</div>
				`);
			} else if(fieldObj.type === 'select' || fieldObj.type === 'radio') {
				const { prevalues, isArray } = getPrevalues(fieldObj);
				dataArray.push(`<div>${fieldObj.caption}: ${values[key] ? (isArray ? values[key] : prevalues[values[key]]) : '-'}</div>`);
			} else if(fieldObj.type === 'checkboxList') {
				const { prevalues, isArray } = getPrevalues(fieldObj);
				dataArray.push(`<div>${fieldObj.caption}: ${values[key] ? (isArray ? values[key] : values[key].map(k => prevalues[k])) : '-'}</div>`);
			} else if(fieldObj.type === 'checkbox') {
				dataArray.push(`<div>${fieldObj.caption}: ${values[key] ? 'Ja' : 'Nej'}</div>`);
			} else if(fieldObj.type !== 'recaptcha2' && fieldObj.type !== 'titleAndDescription') { 
				dataArray.push(`<div>${fieldObj.caption}: ${values[key] || '-'}</div>`);	
			}
		}
	}
	const formContent = dataArray.join('');

	if(!emailContent) {
		content = formContent;
	} else {
		content = replaceMarkers(emailContent, {...values, form: formContent });
	}

	const html = 
			`<html lang="en">
				<head>
					<meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">
					<meta http-equiv="X-UA-Compatible" content="ie=edge">
					<title>${name}</title>
				</head>
				<body>
				${content}
				</body>
			</html>`;

	return html;
};
