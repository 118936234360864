import React from 'react';
import { IRichTextModule } from '../types/heartcore.types';
import RawHtml from './raw-html';

const RichTextModule = (props: IRichTextModule) => {
	const { bodyText, disableTopMargin, disableBottomMargin } = props;
	return (
		<section className={`rich-text-module box-wide ${disableTopMargin ? 'rich-text-module--no-top-margin' : ''} ${disableBottomMargin ? 'rich-text-module--no-bottom-margin' : ''}`}>
			<RawHtml content={bodyText} />
		</section>
	);
};

export default RichTextModule;
