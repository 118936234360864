const fetchData = async (url, ...args) => {
	try {
		const response = await fetch(url, ...args);
		return { ok: true, data: response };
	} catch(e) {
		console.error(e);
		return { ok: false, message: e };
	}
};

const fetchHeartcoreData = async (url, { method, headers, body = null, isJson = true }) => {
	const response = await fetchData(url, {
		method: method,
		headers: {
			'Umb-Project-Alias': process.env.GATSBY_UMBRACO_HEARTCORE_PROJECT_ALIAS,
			'Api-Key': process.env.GATSBY_UMBRACO_HEARTCORE_API_KEY,
			...headers,
		},
		body: body,
	});

	if(response.ok) {
		const data = isJson ? await response.data.json() : response.data;
		return data;
	}

	return response;
};


module.exports = {
	fetchHeartcoreData: fetchHeartcoreData,
	fetchData: fetchData,
};