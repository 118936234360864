import React from 'react';
import { useField } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { IRecaptcha } from '../../types/heartcore.types';

const Recaptcha = (props: IRecaptcha) => {
	const [field, meta, helpers] = useField({name:props.alias, placeholder:props.settings.placeholder});
	const { setValue } = helpers;
	return (
		<div className='input-wrapper'>
			<ReCAPTCHA
				{...field}
				sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
				theme={props.settings.theme || 'light'}
				size={props.settings.size || 'normal'}
				label={props.caption}
				onChange={(response) => { setValue(response); }}
				hl='da'
			/>
			{meta.touched && meta.error ? (
				<div className='input-error'>{meta.error}</div>
			) : null}
		</div>
	);
};

export default Recaptcha;