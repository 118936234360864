import React, { useContext, useMemo } from 'react';
import { ILink, IMedia } from '../types/heartcore.types';
import LinkWithPageTransition from './link-with-page-transition';
import Picture from './heartcore-picture';
import { IHeartcorePictureQuery } from '../types/global.types';
import CarouselArrow from './svg/carousel-arrow';
import LokaltogContext from '../context/context';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/scss';

interface ImediaProps extends IMedia {
	crops?: IHeartcorePictureQuery[]
}

SwiperCore.use([Pagination, Autoplay]);

const NextButton = () => {
	const swiper = useSwiper();
	return (
		<button className='hero-carousel__arrow' onClick={() => swiper.slideNext()}>
			<CarouselArrow />
		</button>
	);
};

const PrevButton = () => {
	const swiper = useSwiper();
	return (
		<button className='hero-carousel__arrow hero-carousel__arrow--left' onClick={() => swiper.slidePrev()}>
			<CarouselArrow />
		</button>
	);
};

interface IMultiImageProps extends ImediaProps {
	umbracoExtension: string
}

interface IHeroCarruselProps {
	contentTyopeAlias: string;
	heroCarousel: {
		headline: string;
		bodyText: string;
		link: ILink;
		media: IMultiImageProps;
		mediaMobile: IMultiImageProps;
	}[];
}

const HeroCarousel = (props: IHeroCarruselProps) => {
	const { heroCarousel } = props;
	const globalContext = useContext(LokaltogContext);
	const isMobile = useMemo(() => globalContext.windowWidth < 769, [globalContext.windowWidth]);

	const queries = [
		{ alias: 'desktopExtraExtraLarge', height: 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: 1094, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: 957, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: 768, width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 584, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 554, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 554, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 554, width: 480, media: '(max-width: 480px)' },
	];

	return (
		<section className='hero-carousel'>
			<Swiper
				slidesPerView={1}
				spaceBetween={0}
				loop={true}
				autoplay={{ delay: 7000 }}
				pagination={{ clickable: true }}
			>
				{heroCarousel.map(({ headline, bodyText, link, media, mediaMobile }, idx) => (
					<SwiperSlide key={`hero-carousel-${idx}`} className='hero-carousel__item'>
						{isMobile && mediaMobile !== null ?
							<>{mediaMobile.umbracoExtension === 'mp4' ? 
								<video className='hero__video hero__mobile' autoPlay playsInline loop muted>
									<source src={mediaMobile._url} type='video/mp4' />
								</video>
								:
								<Picture image={mediaMobile} queries={queries}/>
							}</>
							:
							<>
								{media?.umbracoExtension === 'mp4' ?
									<>
										<video className='hero__video hero_desktop' autoPlay playsInline loop muted>
											<source src={media._url} type='video/mp4' />
										</video>
									</>
									:
									<Picture image={media} queries={queries}/>
								}
							</>
						}
						<div className='hero-carousel__item-content box-wide'>
							<h2 className='hero-carousel__title'>{headline}</h2>
							<p className='hero-carousel__text'>{bodyText}</p>
							{link && <LinkWithPageTransition classes='button' url={link.url} external={link.type === 'EXTERNAL' || link.type === 'MEDIA'} target={link.target}>{link.name}</LinkWithPageTransition>}
						</div>
					</SwiperSlide>
				))}
				<PrevButton />
				<NextButton />
			</Swiper>
		</section>
	);
};

export default HeroCarousel;
