import React from 'react';
import { ITreeTilesModule } from '../types/heartcore.types';
import HeartcorePicture from './heartcore-picture';
import LinkWithPageTransition from './link-with-page-transition';

const ThreeTilesModule = (props: ITreeTilesModule) => {
	const { headline, tiles, disableTopMargin, disableBottomMargin } = props;
	const queries = [
		{ alias: 'desktop', height: 325, width: 308, media: '(min-width: 1024px)' },
		{ alias: 'tablet', height: 230, width: 290, media: '(min-width: 769px)' },
		{ alias: 'mobile', height: 223, width: 230, media: '(max-width: 768px)' },
	];

	return (
		<section className={`three-tiles-module box-wide ${disableTopMargin ? 'three-tiles-module--no-top-margin' : ''} ${disableBottomMargin ? 'three-tiles-module--no-bottom-margin' : ''}`}>
			<h2  className='three-tiles-module__headline'>{headline}</h2>
			<div className='three-tiles-module__tiles-container'>
				{tiles.map((tile, index) => 
					<div className='three-tiles-module__tile-wrapper' key={`${tile.headline}-${index}`}>
						<div className='three-tiles-module__image-wrapper'>
							<HeartcorePicture image={tile.image} queries={queries} />
						</div>
						<h3>{tile.headline}</h3>
						<p className='three-tiles-module__description'>{tile.description}</p>
						{tile.link && <LinkWithPageTransition classes='link' url={tile.link.url} external={!tile.link.udi} target={tile.link.target}>{tile.link.name}</LinkWithPageTransition>}
					</div>,
				)}
			</div>
		</section>
	);
};

export default ThreeTilesModule;
