import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import LokaltogContext from '../context/context';
import { INotifications } from '../types/heartcore.types';
import { timeout } from '../utils/utils';
import RawHtml from './raw-html';
import NotificationIcon from './svg/notification-icon';

const Notifications = (props: INotifications) => {
	const { notifications, triggerHeightUpdateAfter } = props;
	if (!notifications || notifications.length === 0) { return; }

	const lokaltogContext = useContext(LokaltogContext);
	const infoEntriesContainerRef = useRef(null);
	const notificationEntries = useRef([]);
	const [ currentNotificationIndex, setCurrentNotificationIndex ] = useState(0);
	const [ relevantSizeOfInnerContainer, setRelevantSizeOfInnerContainer] = useState(0);
	let transitionTimeout = null;

	const animateDesktop = async () => {
		if(notifications.length > 1) {
			const indexOfEntryToMoveOutOfView = currentNotificationIndex === 0 ? notifications.length - 1 : currentNotificationIndex - 1;
			notificationEntries.current[indexOfEntryToMoveOutOfView].style.transform = `translateY(calc(-50% - ${relevantSizeOfInnerContainer}px))`;

			if (notifications.length > 2) {
				let upcomingEntry = notificationEntries.current[currentNotificationIndex + 1];
				if(!upcomingEntry) {
					upcomingEntry = notificationEntries.current[0];
				}
				upcomingEntry.style.transform = `translateY(${relevantSizeOfInnerContainer}px)`;
			} else {
				transitionTimeout = setTimeout(() => notificationEntries.current[indexOfEntryToMoveOutOfView].style.transform = `translateY(${relevantSizeOfInnerContainer}px)`, 500);
			}
		}
		notificationEntries.current[currentNotificationIndex].style.transform = 'translateY(-50%)';
	};

	const animateMobile = () => {
		if(notifications.length > 1) {
			const indexOfEntryToMoveOutOfView = currentNotificationIndex === 0 ? notifications.length - 1 : currentNotificationIndex - 1;
			notificationEntries.current[indexOfEntryToMoveOutOfView].style.transform = `translateX(-${relevantSizeOfInnerContainer}px)`;

			if (notifications.length > 2) {
				let upcomingEntry = notificationEntries.current[currentNotificationIndex + 1];
				if(!upcomingEntry) {
					upcomingEntry = notificationEntries.current[0];
				}
				upcomingEntry.style.transform = `translateX(${relevantSizeOfInnerContainer}px)`;
			} else {
				transitionTimeout = setTimeout(() => notificationEntries.current[indexOfEntryToMoveOutOfView].style.transform = `translateX(${relevantSizeOfInnerContainer}px)`, 500);
			}
		}

		notificationEntries.current[currentNotificationIndex].style.transform = 'translateX(0)';
	};


	const setContainerMaxHeight = async () => {
		if(triggerHeightUpdateAfter) {//when transitioning frm routes page to single route - the transition animation delays drawing
			await timeout(triggerHeightUpdateAfter);
		}

		const biggestElHeight = notificationEntries.current.reduce((prev, curr) => prev > curr.offsetHeight ? prev : curr.offsetHeight, 0);
		infoEntriesContainerRef.current.style.height = `${biggestElHeight}px`;

		if(window.innerWidth > 640) {
			setRelevantSizeOfInnerContainer(biggestElHeight);
		} else {
			setRelevantSizeOfInnerContainer(infoEntriesContainerRef.current.offsetWidth);
		}
	};

	useEffect(() => {		
		if(window.innerWidth > 640) {
			animateDesktop();
		} else {
			animateMobile();
		}

		return () => clearTimeout(transitionTimeout);

	}, [currentNotificationIndex, relevantSizeOfInnerContainer]);


	useEffect(() => {
		setContainerMaxHeight();

		if(notifications.length > 1) {
			const interval = setInterval(() => {
				setCurrentNotificationIndex(prev => prev === notifications.length - 1 ? 0 : prev + 1);
			}, 4000);
	
			return () => clearInterval(interval);
		}
	},[lokaltogContext.windowWidth]);

	return (
		<section className='notifications box-wide'>
			<div className='notifications__headline-wrapper'>
				<NotificationIcon />
				<h6 className='notifications__headline'>{lokaltogContext.tr('Notifications.Caption')}</h6>
			</div>
			<div className='notifications__info-container' ref={infoEntriesContainerRef}>
				{notifications.map((notification, i) => 
					<div ref={(el) => notificationEntries.current[i] = el} className={`notifications__info-entry ${i === currentNotificationIndex ? 'current' : ''}`} key={`notification-${i}`}>
						{notification.route ?
							<>
								<div className='notifications__route-info'>{notification.route.number} {notification.route.title}</div>
								<RawHtml classes='notifications__info-text' content={notification.route.emergencyInformation} />
							</>
							:
							<RawHtml classes='notifications__info-text' content={notification.infoText} />
						}
					</div>,
				)}
			</div>
			{notifications.length > 1 
				?
				<div className='notifications__counter-wrapper'>
					<span className='notifications__counter'>{currentNotificationIndex + 1}</span>
					<span className='notifications__counter'>&nbsp;/&nbsp;{notifications.length}</span>
				</div>
				:
				null}
		</section>
	);
};

export default Notifications;
