import React from 'react';
import { IImageBanner } from '../types/heartcore.types';
import HeartcorePicture from './heartcore-picture';
import LinkWithPageTransition from './link-with-page-transition';
import RawHtml from './raw-html';

const ImageBanner = (props: IImageBanner) => {
	const { caption, headline, description, image, link, placeImageOnTheLeft, template, backgroundColor, disableTopMargin, disableBottomMargin } = props;

	const withLargeImage = template === 'with-large-image';
	const queries = withLargeImage ?
		[
			{ alias: 'desktopExtraLarge', height: 768, width: 794, media: '(min-width: 1536px)' },
			{ alias: 'desktopLarge', height: 768, width: 951, media: '(min-width: 1280)' },
			{ alias: 'desktop', height: 768, width: 792, media: '(min-width: 1024px)' },
			{ alias: 'desktopSmall', height: 768, width: 998, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 768, width: 758, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 768, width: 604, media: '(min-width: 481px)' },
			{ alias: 'mobile', height: 768, width: 444, media: '(max-width: 480px)' },
		] : [
			{ alias: 'desktopExtraLarge', height: 552, width: 640, media: '(min-width: 1536px)' },
			{ alias: 'desktopLarge', height: 552, width: 768, media: '(min-width: 1280px)' },
			{ alias: 'desktop', height: 552, width: 640, media: '(min-width: 1024px)' },
			{ alias: 'desktopSmall', height: 552, width: 998, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 552, width: 758, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 552, width: 604, media: '(min-width: 481px)' },
			{ alias: 'mobile', height: 552, width: 444, media: '(max-width: 480px)' },
		];
	const templateWithBackground = template === 'with-background';
	const style = templateWithBackground ? { backgroundColor:`#${backgroundColor}` } : null;
	return (
		<section 
			className={`
				image-banner 
				box-wide 
				image-banner--${template} 
				${placeImageOnTheLeft ? 'image-banner--image-on-the-left' : ''} 
				${disableTopMargin ? 'image-banner--no-top-margin' : ''} 
				${disableBottomMargin ? 'image-banner--no-bottom-margin' : ''}`} 
			style={style}
		>
			<div className='image-banner__content-wrapper'>
				<span className='image-banner__caption'>{caption}</span>
				<h2 className='image-banner__headline'>{headline}</h2>
				<RawHtml classes='image-banner__description' content={description} />
				{link && <LinkWithPageTransition classes={`button ${templateWithBackground ? 'button--white' : ''}`} url={link.url} external={link.type === 'EXTERNAL' || link.type === 'MEDIA'} target={link.target}>{link.name}</LinkWithPageTransition>}
			</div>
			{image &&
			<div className='image-banner__image-wrapper'>
				<HeartcorePicture image={image} queries={queries} />
			</div>
			}
		</section>
	);
};

export default ImageBanner;
