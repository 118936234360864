import React from 'react';
import { IMediaBanner } from '../types/heartcore.types';
import HeartcorePicture from './heartcore-picture';
import Video from './video';

const MediaBanner = (props: IMediaBanner) => {
	const { image, video, caption,fullWidth, keepOriginalAspectRatio, disableTopMargin, disableBottomMargin } = props;

	const fullWidthQueries = [
		{ alias: 'desktopExtraExtraLarge', height: keepOriginalAspectRatio ? null : 1459, width: 2560, media: '(min-width: 1920px)' },
		{ alias: 'desktopExtraLarge', height: keepOriginalAspectRatio ? null : 1094, width: 1920, media: '(min-width: 1680px)' },
		{ alias: 'desktopLarge', height: keepOriginalAspectRatio ? null : 957, width: 1680, media: '(min-width: 1367px)' },
		{ alias: 'desktop', height: keepOriginalAspectRatio ? null : 768, width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: keepOriginalAspectRatio ? null : 584, width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: keepOriginalAspectRatio ? null : 554, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: keepOriginalAspectRatio ? null : 554, width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: keepOriginalAspectRatio ? null : 554, width: 480, media: '(max-width: 480px)' },
	];

	const queries = [
		{ alias: 'desktop', height: keepOriginalAspectRatio ? null : 493, width: 858, media: '(min-width: 900px)' },
		{ alias: 'desktopSmall', height: keepOriginalAspectRatio ? null : 422, width: 732, media: '(min-width: 768px)' },
		{ alias: 'mobileLarge', height: keepOriginalAspectRatio ? null : 562, width: 777, media: '(max-width: 767px)' },
		{ alias: 'mobile', height: keepOriginalAspectRatio ? null : 562, width: 424, media: '(max-width: 415px)' },
	];

	return (
		<section 
			className={`
				media-banner 
				${!fullWidth ? 'box-wide media-banner--narrow' : ''} 
				${keepOriginalAspectRatio ? 'media-banner--origin-aspect-ratio' : ''} 
				${disableTopMargin ? 'media-banner--no-top-margin' : ''} 
				${disableBottomMargin ? 'media-banner--no-bottom-margin' : ''}
			`}
		>
			{video 
				?
				<Video video={video} poster={image} />
				:
				<>
					<div className='media-banner__image-wrapper'><HeartcorePicture image={image} queries={fullWidth ? fullWidthQueries : queries} /></div>
				</>
			}
			{caption && <span className='media-banner__caption'>{caption}</span>}
		</section>
	);
};

export default MediaBanner;
