import React, { useContext } from 'react';
import LokaltogContext from '../context/context';
import { htmlRaw } from '../utils/utils';

//we need to clean up the internal links (remove root node) and keep the external links intact
const RawHtml = ({ content, classes='' }: {content: string, classes?: string }) => {
	const lokaltogContext = useContext(LokaltogContext);

	if(content && !content.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)) {//check if html contains any links
		return <div className={`content-editor ${classes}`} dangerouslySetInnerHTML={htmlRaw(content)}></div>;
	}

	const getParsedHtml = () => {
		if(!content) return '';
		return content.replaceAll(`href="${lokaltogContext.rootNodeUrl}`, 'href="/');
	};

	return (
		<div className={`content-editor ${classes}`} dangerouslySetInnerHTML={{__html: getParsedHtml()}}></div>
	);
};

export default RawHtml;
