import React, { useRef } from 'react';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import { useEffect } from 'react';
import { useState } from 'react';
import PlayIcon from './svg/play-icon';

const Video = ({ video, poster }) => {
	const [ isIOS, setIsIOS ] = useState(false);
	const [ playing, setPlaying ] = useState(false);
	const [ loaded, setLoaded ] = useState(false);
	const videoRef = useRef(null);
	const ref = useRef(null);
	const isIntersecting = useIntersectionObserver(ref, {
		root: null,
		threshold: 0,
		rootMargin: '200px',
	}, false);

	const videoPlayHandler = () => {
		videoRef.current.play();
		setPlaying(true);
	};

	const setVideoControls = (isPlaying) => {
		if(isPlaying) {
			videoRef.current.setAttribute('controls','controls');
		} else {
			videoRef.current.removeAttribute('controls','');
			setPlaying(false);
		}
	};

	const handleLoadedDataForIOS = () => {
		videoRef.current.pause();
		videoRef.current.muted = false;
	};

	useEffect(() => {
		if(videoRef.current && !loaded) {
			videoRef.current.addEventListener('playing', () => setVideoControls(true));
			videoRef.current.addEventListener('pause', () => setVideoControls(false));
			setLoaded(true);
		}
	}, [videoRef.current, isIntersecting]);

	useEffect(() => {
		if([
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) {
			setIsIOS(true);
		}
	}, []);

	return (
		<div ref={ref} className={`relative video ${playing ? 'video--playing' : ''}`}>
			{isIntersecting ? 
				<>
					<video ref={videoRef} preload='metadata' playsInline className='video__content' poster={poster?._url} onClick={videoPlayHandler} autoPlay={isIOS} muted={isIOS} onLoadedData={isIOS ? handleLoadedDataForIOS : null}>
						<source src={video._url} type='video/mp4' />
					</video>
					<PlayIcon />
				</>
				: null}
		</div>
	);
};

export default Video;
