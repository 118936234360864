import React, { useContext, useRef, useCallback, useEffect, useState } from 'react';
import gsap from 'gsap';
import ScrollToPlugin from '../libs/ScrollToPlugin';
import { IInteractiveTrain } from '../types/heartcore.types';
import Train from './svg/train';
import LokaltogContext from '../context/context';
import { useMemo } from 'react';

const interactiveTrainAnimationTimeline = gsap.timeline();
const CONTAINER_WIDTH = 308;

const InteractiveTrain = (props: IInteractiveTrain) => {
	const { firstSectionText, secondSectionText, thirdSectionText, fourthSectionText, disableTopMargin, disableBottomMargin } = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const svgRef = useRef<SVGElement>(null);
	const sectionsRefArray = useRef([]);
	const helperTextRef = useRef(null);
	const section1MarkerRef = useRef<SVGCircleElement>(null);
	const section2MarkerRef = useRef<SVGCircleElement>(null);
	const section3MarkerRef = useRef<SVGCircleElement>(null);
	const section4MarkerRef = useRef<SVGCircleElement>(null);
	const [ sections, setSections ] = useState([]);
	const [ activeSectionIndex, setActiveSectionIndex ] = useState(null);
	const [ helperTextHidden, setHelperTextHidden ] = useState(false);
	const globalContext = useContext(LokaltogContext);
	const isMobile = useMemo(() => globalContext.windowWidth < 769, [globalContext.windowWidth]);
	const sectionButtonDefaultSize = useMemo(() => isMobile || globalContext.windowWidth > 1536 ? 50 : 30, [globalContext.windowWidth]);

	const getXAndYPos = (marker: SVGCircleElement) => {
		const markerRect = marker.getBoundingClientRect();
		return {
			y: markerRect.top - containerRef.current.getBoundingClientRect().top,
			x: markerRect.left - svgRef.current.getBoundingClientRect().left,
		};
	};

	const measuredRef = useCallback((node) => {
		setSections(prev => {
			prev[node.dataset.index].textHeight = node.offsetHeight;
			return prev;
		});
	}, []);

	const toggleSectionHandler = (index) => {
		if(activeSectionIndex !== null) {
			gsap.to(sectionsRefArray.current[activeSectionIndex], {
				height: sectionButtonDefaultSize,
				width: sectionButtonDefaultSize,
				zIndex: 0,
				duration: .3,
			});
			gsap.to(sectionsRefArray.current[activeSectionIndex], {
				borderRadius: '50%',
				duration: .1,
				delay: .2,
			});
		}
		setActiveSectionIndex(activeSectionIndex === index ? null : index);
	};

	const onContainerScroll = () => {
		if(!isMobile) { return; }

		if(containerRef.current.scrollLeft < svgRef.current.getBoundingClientRect().width - (globalContext.windowWidth * 2) && !helperTextHidden) {
			interactiveTrainAnimationTimeline.to(helperTextRef.current, {
				opacity: 0,
				duration: 1,
			});
			setHelperTextHidden(true);
		} else if(containerRef.current.scrollLeft > svgRef.current.getBoundingClientRect().width - (globalContext.windowWidth * 2) && helperTextHidden) {
			interactiveTrainAnimationTimeline.to(helperTextRef.current, {
				opacity: 1,
				duration: 1,
			});
			setHelperTextHidden(false);
		}
	};

	const updateSectionsPosition = () => {
		setSections(prev => {
			return prev.map(section => {
				const { x, y } = getXAndYPos(section.markerRef.current);
				return {
					...section,
					yPos: y,
					xPos: x,
				};
			});
		});


		if(isMobile) {
			gsap.set(containerRef.current, {
				scrollTo: { x: svgRef.current.getBoundingClientRect().width },
			});
		}
	};

	useEffect(() => {
		if(activeSectionIndex !== null) {
			gsap.to(sectionsRefArray.current[activeSectionIndex], {
				borderRadius: '0.9375rem',
				duration: .1,
			});
			gsap.to(sectionsRefArray.current[activeSectionIndex], {
				height: sections[activeSectionIndex].textHeight,
				width: CONTAINER_WIDTH,
				zIndex: 1,
				duration: .3,
				delay: .1,
				onComplete: () => {
					if(isMobile) {
						const sectionRect = sectionsRefArray.current[activeSectionIndex].getBoundingClientRect();
						const centeringOffset = globalContext.windowWidth / 2 - sectionRect.width / 2;
						gsap.to(containerRef.current, {
							scrollTo: { x: containerRef.current.scrollLeft + sectionRect.x -centeringOffset },
						});
					}
				},
			});
		}
	}, [activeSectionIndex]);

	useEffect(() => {
		gsap.registerPlugin(ScrollToPlugin);

		const initialSectionsData = [
			{
				text: firstSectionText,
				markerRef: section1MarkerRef,
				positionClass: 'interactive-train__section--top-left',
			},
			{
				text: secondSectionText,
				markerRef: section2MarkerRef,
				positionClass: 'interactive-train__section--bottom-left',
			},
			{
				text: thirdSectionText,
				markerRef: section3MarkerRef,
				positionClass: '',
			},
			{
				text: fourthSectionText,
				markerRef: section4MarkerRef,
				positionClass: 'interactive-train__section--bottom-right',
			},
		];
		setSections(initialSectionsData);
		updateSectionsPosition();

		return () => {
			interactiveTrainAnimationTimeline.kill();
		};
	},[]);

	useEffect(() => {
		if(globalContext.windowWidth > 0 && sections.length > 0) {
			updateSectionsPosition();

			if(activeSectionIndex) {
				toggleSectionHandler(null);
			}
		}
	},[globalContext.windowWidth]);

	return (
		<section className={`interactive-train ${disableTopMargin ? 'interactive-train--no-top-margin' : ''} ${disableBottomMargin ? 'interactive-train--no-bottom-margin' : ''}`}>
			<div className='interactive-train__inner-wrapper' ref={containerRef} onScroll={onContainerScroll}>
				<div className='interactive-train__svg-wrapper'>
					<Train svgRef={svgRef} section1MarkerRef={section1MarkerRef} section2MarkerRef={section2MarkerRef} section3MarkerRef={section3MarkerRef} section4MarkerRef={section4MarkerRef} />
				</div>
				<div className='interactive-train__sections-wrapper'>
					{sections.map((section, index) => 
						<div 
							ref={(el) => sectionsRefArray.current[index] = el} 
							className={`interactive-train__section ${activeSectionIndex === index ? 'interactive-train__section--active' : ''} ${section.positionClass}`} 
							key={`interactive-train-section-${index}`} 
							style={{'top': `${section.yPos}px`, 'left': `${section.xPos}px`}}
						>
							<button
								className='interactive-train__section__button'
								aria-label='open section' 
								onClick={() => toggleSectionHandler(index)}
								onBlur={() => toggleSectionHandler(null)}
							></button>
							<p ref={measuredRef} className='interactive-train__section__text' data-index={index}>{section.text}</p>
						</div>,
					)}
				</div>
			</div>
			{isMobile ?
				<p  ref={helperTextRef} className='interactive-train__mobile-helper-text'>{globalContext.tr('InteractiveTrain.MobileSwipeHelperText')}</p>
				: ''}
		</section>
	);
};

export default InteractiveTrain;
