
export const replaceMarkers = (content, values) => {
	const finalArray = [];
	const splitOnOpeningMarkers = content.split('#/#');
	splitOnOpeningMarkers.forEach((str, index) => {
		if(index === 0) { finalArray.push(str); }
		const splitOnClosingMarker = str.split('#\\#');
		const replacedValue = values[splitOnClosingMarker[0]] || '';
		finalArray.push(replacedValue);
		finalArray.push(splitOnClosingMarker[1]);
	});
	
	return finalArray.join('');
};