import React from 'react';
import { IQuote } from '../types/heartcore.types';
import HeartcorePicture from './heartcore-picture';


const Quote = (props: IQuote) => {
	const { quote, author, authorCaption, image, largeText, disableTopMargin, disableBottomMargin } = props;
	
	const queries = [
		{ alias: 'desktop', height: 627, width: 640, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 551, width: 998, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 554, width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 551, width: 604, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 550, width: 444, media: '(max-width: 480px)' },
	];

	return (
		<section className={`quote box-wide ${largeText ? 'quote--large-text' : ''} ${disableTopMargin ? 'quote--no-top-margin' : ''} ${disableBottomMargin ? 'quote--no-bottom-margin' : ''}`}>
			<div className='quote__image-wrapper'>
				<HeartcorePicture image={image} queries={queries}/>
			</div>
			<div className='quote__text-wrapper'>
				<p className='quote__text'>	&ldquo;{quote}&rdquo;</p>
				<p className='quote__author'>{author}</p>
				<p className='quote__author-caption'>{authorCaption}</p>
			</div>
		</section>
	);
};

export default Quote;