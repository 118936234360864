import React from 'react';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { useState } from 'react';
import { IFormPicker, IMultiFormPicker } from '../types/heartcore.types';
import Dropdown from './form/dropdown';
import FormPicker from './form-picker';

const MultiFormPicker = ({forms, dropdownCaption, disableTopMargin, disableBottomMargin}: IMultiFormPicker) => {
	const [ formOptions, setFormOptions ] = useState(null);
	const [ selectedForm, setSelectedForm ] = useState<IFormPicker>(null);
	const [ pendingForm, setPendingForm ] = useState<IFormPicker>(null);

	useEffect(() => {
		const formattedDropdownOptions = {};
		forms.forEach((f) => {
			if(f.selectedForm !== null && f.selectedForm?._id && f.selectedForm?.name) {
				formattedDropdownOptions[f.selectedForm._id] = f.selectedForm.name;
			}
		});
		setFormOptions(formattedDropdownOptions);
	}, []);

	useEffect(() => {
		setSelectedForm(pendingForm);
	}, [pendingForm]);

	const handleFormChange = (value) => {
		if(value.selectedForm !== pendingForm?.selectedForm._id) {
			if(selectedForm) {
				setSelectedForm(null);
			}
			const selectedFormPickerData = forms.find(f => f.selectedForm._id === value.selectedForm);
			setPendingForm(selectedFormPickerData);
		}
	};

	return (
		<>
			<div className={`multi-form-picker box-wide ${disableTopMargin ? 'multi-form-picker--no-top-margin' : ''} ${disableBottomMargin ? 'multi-form-picker--no-bottom-margin' : ''}`}>
				<div className='multi-form-picker__inner'>
					<Formik
						initialValues={{}}
						validate={handleFormChange} 
						onSubmit={null}
					>
						{formOptions && <Dropdown preValues={formOptions} caption={dropdownCaption} alias='selectedForm' type='select' />}
					</Formik>
				</div>
			</div>
			{selectedForm ? <FormPicker {...selectedForm} /> : <div className='multi-form-picker-placeholder'></div>}
		</>
	);
};

export default MultiFormPicker;
