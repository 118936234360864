const setCondition = (rule, values) => {
	let isConditionSatisfied;
	if(Array.isArray(values[rule.field])) {
		isConditionSatisfied = values[rule.field].find(v => v === rule.value);
	} else {
		isConditionSatisfied = values[rule.field] === rule.value;
	}
	return isConditionSatisfied;
};

export const checkFieldCondition = (condition, values) => {
	let satisfiesCondition;
	if(condition.logicType === 'ALL') {
		satisfiesCondition = true;
		condition.rules.forEach(r => {
			if(satisfiesCondition) {
				satisfiesCondition = setCondition(r, values);
			}
		});
	} else {
		satisfiesCondition = false;
		condition.rules.forEach(r => {
			if(!satisfiesCondition) {
				satisfiesCondition = setCondition(r, values);
			}
		});
	}

	return satisfiesCondition;
};