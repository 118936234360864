//Umbraco expects the form submit different values depending if prevalues are object or an array
export const getFormatOptionsFromPrevalues = (preValues) => {
	if(Array.isArray(preValues)) {
		return preValues.map(val => ({value: val, label: val}));
	}
	const formattedOptions = [];
	for(const key in preValues) {
		formattedOptions.push({value: key, label:preValues[key]});
	}
	return formattedOptions;
};