import { isFileUploadField } from './file-upload-helpers';

export const getDefaultValue = (alias, field) => {

	if(isFileUploadField(field)) {
		return {[alias]: []};
	}

	return {[alias]: field.settings?.defaultValue || ''};
};
