import React from 'react';
import { useFieldConditioning } from '../../hooks/use-field-conditioning';
import { ITitleAndDescription } from '../../types/heartcore.types';

const TitleAndDescription = (props: ITitleAndDescription) => {
	const showInput = useFieldConditioning(props);

	if(!showInput) { return <></>; }

	return (
		<div className='input-wrapper input-wrapper--title-and-description'>
			<div className='input-inner-wrapper'>
				<h5>{props.settings.caption}</h5>
				<p>{props.settings.bodyText}</p>
			</div>
		</div>
	);
};

export default TitleAndDescription;