import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { IAccordion, IAccordionItem } from '../types/heartcore.types';
import RawHtml from './raw-html';
import clsx from 'clsx';
interface AccordionItem {
	headline: string;
	bodyText?: string;
	useHTML?: boolean;
	children?: React.ReactNode[] | React.ReactNode;
	openByDefault?: boolean;
}

export const AccordionItem = ({ headline, useHTML = false, children, bodyText = '', openByDefault }: AccordionItem) => {
	const [collapsed, setCollapsed] = useState(!openByDefault);
	const contentRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const toggleContent = () => {
		setCollapsed((prev) => !prev);
	};

	useEffect(() => {
		setCollapsed(!openByDefault);
	}, [openByDefault]);

	useEffect(() => {
		if(contentRef.current && containerRef.current) {
			if (!collapsed) {
				containerRef.current.style.height = `${contentRef.current.clientHeight}px`;
			} else {
				containerRef.current.style.height = '0';
			}
		}
	}, [collapsed, contentRef, containerRef]);

	return (
		<li className='accordion__item'>
			<button className={clsx('accordion__button', {'accordion__button--closed' : collapsed})} onClick={toggleContent} aria-label={collapsed ? 'Open' : 'Close'}>
				<p className='accordion__display-text'>{headline}</p>
				<div className={clsx('accordion__icon', {'accordion__icon--rotated': !collapsed})}></div>
			</button>
			<div className='accordion__container' ref={containerRef}>
				<div className='accordion__content' ref={contentRef}>
					{useHTML ? 
						<RawHtml classes='accordion__rte' content={bodyText} />
						:
						children
					}
				</div>
			</div>
		</li>
	);
};

const Accordion = (props: IAccordion) => {
	const { headline, entries, disableBottomMargin, disableTopMargin, disableBoxWide } = props;

	return (
		<section className={`${disableBoxWide ? '' : 'box-wide'} accordion__wrapper ${disableTopMargin ? 'accordion__wrapper--no-top-margin' : ''} ${disableBottomMargin ? 'accordion__wrapper--no-bottom-margin' : ''}`}>
			<h2>{headline}</h2>
			<div className='accordion'>
				<ul>
					{entries.length && entries.map((entry: IAccordionItem) =>
						<AccordionItem headline={entry.headline} bodyText={entry.bodyText} useHTML={true} key={entry.headline} />,
					)}
				</ul>
			</div>
		</section>
	);
};

export default Accordion;
