import React from 'react';

const CarouselArrow = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.636 44.331'>
			<defs>
				<clipPath id='clip-path'>
					<rect width='19.636' height='44.331' fill='#fff'/>
				</clipPath>
			</defs>
			<g transform='translate(0 0)'>
				<g transform='translate(0 0)' clipPath='url(#clip-path)'>
					<path id='Path_404' d='M17.675,17.521.915.761A6.616,6.616,0,0,0,0,0V3.047L16.074,19.122a4.448,4.448,0,0,1,0,6.286L0,41.481V44.33a6.6,6.6,0,0,0,1.4-1.046L17.675,27.008a6.716,6.716,0,0,0,0-9.488' transform='translate(0 0)' fill='#fff'/>
				</g>
			</g>
		</svg>
	);
};

export default CarouselArrow;
